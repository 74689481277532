import React, { useEffect, useState } from "react";

import ErrorPopup from "./ErrorPopup";
import RadioGroup from "./partials/RadioGroup";

import { withTranslation } from "react-i18next";

import { useAuth } from "../../lib/auth";

import "./Signup.scss";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signup(props) {
	const { t } = props;

	const { register } = useAuth();

	const [state, setState] = useState({
		name: "",
		nif: "",
		code: "",
		country: "",
		email: "",
		password: "",
		password2: "",
		terms: false,
		privacy: false,
		success: false,
		countries: null,
		genericError: "",
	});

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setState({ ...state, countries: [] });
	};

	const closePopup = (event) => {
		if (event) event.preventDefault();
		setState({ ...state, genericError: "" });
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setState({ ...state, [name]: value });
	};

	const validate = () => {
		let { name, nif, code, country, email, password, password2 } = state;

		const isValidName = name.length < 3 ? null : true;
		if (!isValidName)
			toast.error(
				t("error_msg.company_name", "Nome de empresa inválido!")
			);

		let isValidCountry = true,
			isValidNif = true,
			isValidCode = true;

		if (process.env.REACT_APP_VERSION === "sb:user:en") {
			isValidCountry = country !== "";
			if (!isValidCountry)
				toast.error(t("error_msg.country", "País inválido!"));
		} else {
			isValidNif = /^\d+$/.test(nif) && nif.length === 9;
			if (!isValidNif)
				toast.error(t("error_msg.vat_number", "NIF inválido!"));

			isValidCode = /^\d+$/.test(code) && code.length === 10;
			if (!isValidCode)
				toast.error(
					t("error_msg.client_code", "Código de cliente inválido!")
				);
		}

		const pattern =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const isValidEmail = pattern.test(email);
		if (!isValidEmail) toast.error(t("error_msg.email", "Email inválido!"));

		// const isValidPassword = password.length >= 8;
		// if (!isValidPassword) {
		// 	toast.error(
		// 		t(
		// 			"error_msg.password_min",
		// 			"Password inválida (mínimo de 8 caracteres)!"
		// 		)
		// 	);
		// }

		// const passwordsMatch = password === password2;

		// if (isValidPassword && !passwordsMatch) {
		//     toast.error(t('error_msg.password_equal', 'As passwords não são iguais.'));
		// }

		return (
			isValidName &&
			isValidNif &&
			isValidCode &&
			isValidCountry &&
			isValidEmail
		);
	};

	const handleToggle = (event) => {
		setState({
			...state,
			[event.id]: event.selected === t("common.txt_yes", "Sim"),
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const { terms, privacy } = state;

		if (!validate()) return;

		if (!(terms && privacy)) {
			toast.error(
				t(
					"error_msg.terms_privacy",
					"Tem de aceitar os termos e condições e a política de privacidade para se registar."
				)
			);
			return;
		}

		const fields = {
			name: state.name,
			nif: state.nif,
			code: state.code,
			email: state.email,
			password: "00000000",
		};

		register(fields)
			.then(({ createUser }) => {
				if (createUser && createUser.id) {
					setState({ ...state, success: true });
				} else
					toast.error(
						t(
							"error_msg.duplicated_account",
							"Não foi possível criar a conta. Pode já existir uma conta com este e-mail, NIF ou código de cliente. Por favor contacte sbg.direto@superbockgroup.com"
						)
					);
			})
			.catch((e) => {
				toast.error(
					t(
						"error_msg.duplicated_account",
						"Não foi possível criar a conta. Pode já existir uma conta com este e-mail, NIF ou código de cliente. Por favor contacte sbg.direto@superbockgroup.com"
					)
				);
			});

		// let data = '';
		// const { t } = this.props;

		// for (var key in fields) {
		//     if (data.length) data += ',';
		//     data += `${key}: "${fields[key]}"`;
		// }

		// if (this.state.country) {
		//     data += `, country: { connect: { id: "${this.state.country}" } }`;
		// }

		// services.usersApi.add(data)
		//     .then(({ createUser }) => {
		//         if (createUser && createUser.id) {
		//             this.setState({ success: true });
		//         } else this.setState({ genericError: t('error_msg.duplicated_account', 'Não foi possível criar a conta. Pode já existir uma conta com este e-mail, NIF ou código de cliente. Por favor contacte sbg.direto@superbockgroup.com') });
		//     }).catch(e => {
		//         this.setState({ genericError: t('error_msg.duplicated_account', 'Não foi possível criar a conta. Pode já existir uma conta com este e-mail, NIF ou código de cliente. Por favor contacte sbg.direto@superbockgroup.com') });
		//     });
	};

	const { success, genericError } = state;

	if (!success) {
		return (
			<div className="menu">
				<div className="app forms">
					<header className="admin-header">
						<figure className="logo">
							<img
								className="admin-logo"
								src={t("common.logo_url", "/images/logo.svg")}
								alt={t("common.site_name", "Menu Autêntico")}
							/>
						</figure>
					</header>
					<div className="content">
						<div className="form">
							<div className="m-bottom-6 txt-primary">
								<h2>{t("admin_signup.title", "Registar")}</h2>
							</div>
							<form>
								<input
									type="text"
									id="name"
									name="name"
									value={state.name}
									placeholder={t(
										"placeholder.company_name",
										"Nome da Empresa"
									)}
									onChange={handleChange}
								/>

								{process.env.REACT_APP_VERSION !=
								"sb:user:en" ? (
									<input
										type="text"
										name="nif"
										value={state.nif}
										placeholder={t(
											"placeholder.vat_number",
											"NIF"
										)}
										onChange={handleChange}
										className="m-top-4"
									/>
								) : null}

								{process.env.REACT_APP_VERSION !=
								"sb:user:en" ? (
									<input
										type="text"
										name="code"
										value={state.code}
										placeholder={t(
											"placeholder.client_code",
											"Código de Cliente"
										)}
										onChange={handleChange}
										className="m-top-4"
									/>
								) : null}

								<input
									type="email"
									name="email"
									value={state.email}
									placeholder={t(
										"placeholder.email",
										"E-mail"
									)}
									onChange={handleChange}
									className="m-top-4"
								/>
								{/* <input
                                    type="password"
                                    name="password"
                                    value={state.password}
                                    placeholder={t('placeholder.password', "Password")}
                                    onChange={handleChange}                                    
                                    className="m-top-4"
                                />
                                <input
                                    type="password"
                                    name="password2"
                                    value={state.password2}
                                    placeholder={t('placeholder.confirm_password', "Confirmar password")}
                                    onChange={handleChange}
																		className="m-top-4"
                                />
                                 */}
								<div className="row m-top-4">
									<div className="label">
										<h2>
											{t(
												"admin_signup.txt_terms_and_conditions",
												"Declaro que concordo com os "
											)}
											<a
												href={t(
													"admin_signup.url_terms_and_conditions",
													"/pdf/termos-e-condicoes.pdf"
												)}
												target="_blank"
												rel="noreferrer"
											>
												{t(
													"admin_signup.label_terms_and_condtions",
													"Termos e Condições"
												)}
											</a>
											.
										</h2>
									</div>
									<RadioGroup
										id="terms"
										name="number"
										options={[t("common.txt_yes", "Sim")]}
										selected={
											state.terms
												? t("common.txt_yes", "Sim")
												: ""
										}
										onChange={handleToggle}
									/>
								</div>
								<div className="row m-top-4">
									<div className="label">
										<h2>
											{t(
												"admin_signup.txt_privacy_policy",
												"Declaro que concordo com a "
											)}
											<a
												href={t(
													"admin_signup.url_privacy_policy",
													"/pdf/politica-de-privacidade.pdf"
												)}
												target="_blank"
												rel="noreferrer"
											>
												{t(
													"admin_signup.label_privacy_policy",
													"Política de privacidade"
												)}
											</a>
											.
										</h2>
									</div>
									<RadioGroup
										id="privacy"
										name="number"
										options={[t("common.txt_yes", "Sim")]}
										selected={
											state.privacy
												? t("common.txt_yes", "Sim")
												: ""
										}
										onChange={handleToggle}
									/>
								</div>
							</form>
							<div className="m-top-4">
								<a
									onClick={handleSubmit}
									className="btn-primary"
								>
									{t("btn.signup", "Registar")}
								</a>
							</div>
							<div className="m-top-8 txt-primary txt-center">
								<h5>
									{t(
										"admin_signup.txt_login",
										"Já tem conta? Faça login."
									)}
								</h5>
							</div>
							<div className="m-top-8 m-bottom-10">
								<a href="/login" className="btn-secundary">
									{t("btn.login", "Login")}
								</a>
							</div>
						</div>
					</div>
					<ToastContainer
						position="bottom-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss={false}
						draggable
						pauseOnHover={false}
					/>
					<ErrorPopup message={genericError} onClose={closePopup} />
				</div>
			</div>
		);
	} else {
		return (
			<div className="menu">
				<div className="app forms">
					<header className="admin-header">
						<figure className="logo">
							<img
								className="admin-logo"
								src={t("common.logo_url", "/images/logo.svg")}
								alt=""
							/>
						</figure>
					</header>
					<div className="content">
						<div className="form">
							<div className="txt-primary txt-center">
								<h3>
									{t(
										"admin_signup.txt_success_title",
										"Obrigado por se registar."
									)}
									<br></br>
									{t(
										"admin_signup.txt_success_details",
										"Faça login para começar a criar os seus menus."
									)}
								</h3>
							</div>
							<div className="m-top-8 m-bottom-10">
								<a href="/login" className="btn-secundary">
									{t("btn.login", "Login")}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(Signup);
